import { render, staticRenderFns } from "./payment-wallet-list-table.vue?vue&type=template&id=368a9342&scoped=true&"
import script from "./payment-wallet-list-table.vue?vue&type=script&lang=js&"
export * from "./payment-wallet-list-table.vue?vue&type=script&lang=js&"
import style0 from "./payment-wallet-list-table.vue?vue&type=style&index=0&id=368a9342&lang=scss&scoped=true&"
import style1 from "./payment-wallet-list-table.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368a9342",
  null
  
)

export default component.exports
import {QTable,QTr,QTd,QBtn,QIcon,QInnerLoading,QSpinnerIos} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QTr,QTd,QBtn,QIcon,QInnerLoading,QSpinnerIos})
