//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PaymentWalletListTable from '@/modules/payment/components/payment-wallet-list-table.vue';
import PaymentWalletCard from '@/modules/payment/components/payment-wallet-card.vue';

import { mapGetters, mapActions } from 'vuex'
const d = new Date();
// let month = d.getMonth();
export default {
    data(){
      return {
        search: '',
        year: (d.getFullYear()).toString(),
        yearOptions: [
        ],
        month: (d.getMonth() + 1).toString(),
        monthOptions: [
          { 
            value: '1',
            label: 'January'          
          }, 
          { 
            value: '2',
            label: 'February'          
          }, 
          { 
            value: '3',
            label: 'March'          
          }, 
          { 
            value: '4',
            label: 'April'          
          }, 
          { 
            value: '5',
            label: 'May'          
          },
          { 
            value: '6',
            label: 'June'          
          },
          { 
            value: '7',
            label: 'July'          
          },
          { 
            value: '8',
            label: 'August'          
          },
          { 
            value: '9',
            label: 'September'          
          },
          { 
            value: '10',
            label: 'October'          
          },
          { 
            value: '11',
            label: 'November'          
          },
          { 
            value: '12',
            label: 'December'          
          },
          ],
          popupShow: false,
          orderBy: 'createdAt',
          page: 1
      }
    },
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
        }, 
        ...mapActions({
            doFetchWalletTransaction: 'payment/list/doFetchWalletTransaction',
            finishWalletTransactionsLoading: 'payment/list/finishWalletTransactionsLoading'
        }),

        filterFn (val, update) {
          update(() => {
            if (val === '') {
              debugger
              // filterOptions.value = stringOptions
            }
            else {
              console.log(this.month);
              console.log(this.year);
              debugger
              // const needle = val.toLowerCase()
              // filterOptions.value = stringOptions.filter(
              //   v => v.toLowerCase().indexOf(needle) > -1
              // )
            }
          })
        },
        async doFetchTransactionsByDate(year, month){
          console.log('year ',year);
          console.log('month ',month);
          // debugger
          // await this.doFetchWalletTransaction()
          this.filter = {
            month: parseInt(month),
            year: parseInt(year)
          }
          const pagination = {
              sortBy: "desc",
              // limit: 1,
              limit: this.getItemsPerPage,
            }
          await this.doFetchWalletTransaction({
              filter: this.filter, 
              orderBy: this.orderBy,
              pagination
          })
          this.finishWalletTransactionsLoading()

        },
        changePage(value){
          this.page = value
        },
        getYears(startYear) {
            var currentYear = new Date().getFullYear();
            startYear = startYear || 2021;  
            while ( startYear <= currentYear ) {
                // years.push(startYear++);
                this.yearOptions.push({
                  label: startYear.toString(),
                  value: startYear.toString()
                })
                startYear++
            }   
            return this.yearOptions
        }
    },
    created(){
      // console.log(this.getYears(2020));
      this.getYears(2020)
      // debugger
    },
    components: {
      [PaymentWalletListTable.name]: PaymentWalletListTable,
      [PaymentWalletCard.name]: PaymentWalletCard,
    },
    computed:{
        ...mapGetters({
          is_screen_xs: 'layout/is_screen_xs',
          walletTransactions: 'payment/list/walletTransactions',
        }),
       getItemsPerPage() {
          // const { screen } = this.$q;
          //   if (screen.lt.sm) {
          //       return 4;
          //   }
          //   if (screen.lt.md) {
          //       return 6;
          //   }
          //   if (screen.lt.lg) {
          //       return 9;
          //   }
          //   if (screen.lt.xl) {
          //       return 9;
          //   }
            return 10;
        },
    },
}
