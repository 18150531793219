//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions} from 'vuex'
import moment from 'moment';
import i18n from '@/vueI18n'


export default {
    name: 'app-payment-wallet-list-table',
    props:{
        year: {
            type: Number
        },
        month: {
            type: Number
        },
        page: {
            type: Number
        },
    },
    data(){
        return {
            transactionTab: 'wallet',
                 
            
            columnsWallet: [
                {
                    name: 'transactionNumber',
                    required: true,
                    label: this.i18n('entities.payment.fields.transactionNumber'), 
                    align: 'center',
                    field: 'transactionNumber',
                },
                {
                    name: 'transactionId',
                    required: true,
                    label: this.i18n('entities.payment.fields.transactionId'), 
                    align: 'center',
                    field: 'transactionId',
                },
                {
                    name: 'amount',
                    align: 'center',
                    label: this.i18n('entities.payment.fields.amount'),
                    field: 'amount',
                },
                {
                    name: 'transactionDate',
                    align: 'center',
                    label: this.i18n('entities.payment.fields.transactionDate'),
                    field: 'transactionDate',
                },
                {
                    name: 'description',
                    required: true,
                    label: this.i18n('entities.payment.fields.description'), 
                    align: 'center',
                    field: 'description',
                },
                {
                    name: 'operation',
                    align: 'center',
                    label: this.i18n('entities.payment.fields.operation'),
                    field: 'operation',
                },
            ],
            // rowsWallet: [
            //     {
            //         transactionNumber: '#123456',
            //         amount: '300 SR',
            //         transactionDate: 'Sat 25 March',
            //         operation: 'credit',
            //     },
            // ],
            // rowsTrips: [
            //     {
            //         transactionNumber: '#123456',
            //         tripId: 'tripId',
            //         tripName: 'Makkah Trip',
            //         tripStatus: 'pending',
            //         amount: '300 SR',
            //         tripDate: 'Sat 25 March',
            //         operation: 'credit',
            //         clientName: 'Ghada',
            //         tourguideName: 'Ahmed'
            //     },
            //     {
            //         transactionNumber: '#123456',
            //         tripId: 'tripId',
            //         tripName: 'Makkah Trip',
            //         tripStatus: 'pending',
            //         amount: '300 SR',
            //         tripDate: 'Sat 25 March',
            //         operation: 'credit',
            //         clientName: 'Ghada',
            //         tourguideName: 'Ahmed'
            //     },
            //     {
            //         transactionNumber: '#123456',
            //         tripId: 'tripId',
            //         tripName: 'Makkah Trip',
            //         tripStatus: 'pending',
            //         amount: '300 SR',
            //         tripDate: 'Sat 25 March',
            //         operation: 'credit',
            //         clientName: 'Ghada',
            //         tourguideName: 'Ahmed'
            //     },
            //     {
            //         transactionNumber: '#123456',
            //         tripId: 'tripId',
            //         tripName: 'Makkah Trip',
            //         tripStatus: 'pending',
            //         amount: '300 SR',
            //         tripDate: 'Sat 25 March',
            //         operation: 'credit',
            //         clientName: 'Ghada',
            //         tourguideName: 'Ahmed'
            //     },
            //     {
            //         transactionNumber: '#123456',
            //         tripId: 'tripId',
            //         tripName: 'Makkah Trip',
            //         tripStatus: 'pending',
            //         amount: '300 SR',
            //         tripDate: 'Sat 25 March',
            //         operation: 'credit',
            //         clientName: 'Ghada',
            //         tourguideName: 'Ahmed'
            //     },
                
            // ],

            pagination: {
                page: 1,
                rowsPerPage: 10 
            },
            // page: 1,
            filter: {},
            orderBy: 'createdAt'
        }
    },
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
        },
        ...mapActions({
            // doFetchTripsTransaction: 'payment/list/doFetchTripsTransaction',
            doFetchWalletTransaction: 'payment/list/doFetchWalletTransaction',
            finishWalletTransactionsLoading: 'payment/list/finishWalletTransactionsLoading'

        }),
        presenterDate(row, fieldName) {
        // const language = 'en'
        console.log(this.language);
        if(this.language == 'ar'){
            return row[fieldName] ? moment(row[fieldName]).locale('ar').format("D MMM, YYYY") : 'ـــــــ';
        }
            return row[fieldName] ? moment(row[fieldName]).locale('en').format("D MMM, YYYY") : 'ـــــــ';
        },
        async goToPreviousPage(){
            // console.log('go to prev');
            this.filter = {
                month: this.month,
                year: this.year
            }
            const pagination = {
                sortBy: "desc",
                limit: this.getItemsPerPage,
                // limit: 1,
                action: "prev",
                page: this.pageNumber
            }
            await this.doFetchWalletTransaction({
                filter: this.filter, 
                orderBy: this.orderBy,
                pagination
            })
            this.pageNumber -= 1
            this.finishWalletTransactionsLoading()
        },
        async loadMore(){
            this.filter = {
                month: this.month,
                year: this.year
            }
            const pagination = {
                sortBy: "desc",
                limit: this.getItemsPerPage,
                // limit: 1,
                action: "next",
                page: this.pageNumber
            }
            await this.doFetchWalletTransaction({
                filter: this.filter, 
                orderBy: this.orderBy,
                pagination
            })
            console.log(this.pageNumber);
            this.pageNumber += 1
            this.finishWalletTransactionsLoading()
        }
    },

   
    computed:{
        ...mapGetters({
            is_screen_xs: 'layout/is_screen_xs',
            tripTransactions: 'payment/list/tripTransactions',
            countTripTransactions: 'payment/list/countTripTransactions',
            walletTransactions: 'payment/list/walletTransactions',
            countWalletTransactions: 'payment/list/countWalletTransactions',
            loadingWalletTransactions: 'payment/list/loadingWalletTransactions',
            walletTransactionsFirstPage: 'payment/list/walletTransactionsFirstPage',
            walletTransactionsLastPage: 'payment/list/walletTransactionsLastPage',
            currentUser:'auth/currentUser'

        }),
        rows(){
            if(this.transactionTab == 'trips'){
                return this.rowsTrips
            }
            // return this.rowsWallet
            return this.walletTransactions
        },
        columns(){
            if(this.transactionTab == 'trips'){
                return this.columnsTrips
            }
            return this.columnsWallet
        },

        pageNumber: {
            get: function () {
                return this.page;
            },
            set: function (value) {
                this.$emit('changePage', value)
            },
        },
        language(){
            return i18n.locale
        },

        getItemsPerPage() {
          // const { screen } = this.$q;
          //   if (screen.lt.sm) {
          //       return 4;
          //   }
          //   if (screen.lt.md) {
          //       return 6;
          //   }
          //   if (screen.lt.lg) {
          //       return 9;
          //   }
          //   if (screen.lt.xl) {
          //       return 9;
          //   }
            return 10;
        },
    },
    async mounted(){
        this.filter = {
            month: this.month,
            year: this.year,
            walletId:this.currentUser.companyId
        }
        const pagination = {
            sortBy: "desc",
            limit: this.getItemsPerPage
            // limit: 1
        }
        await this.doFetchWalletTransaction({
            filter: this.filter, 
            orderBy: this.orderBy,
            pagination
        })
        this.finishWalletTransactionsLoading()

        // console.log(this.walletTransactions);
        // debugger
    }
}
